import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { pathOr } from 'ramda';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { initReactI18next, I18nextProvider, useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import config from 'config';
import { getCurrentLanguageFromPath } from 'func/localization';
import { USER_PREFERENCES } from 'graphQl/query/user';
import { useAppSelector } from 'hooks/useAppSelector';
import { userToken } from 'store/user/user.selectors';
import { SET_PREFERENCE } from 'graphQl/mutations/User/account';

import Loader from 'components/Base/Loader';

import { Language } from './types';
import { FORCE_LANGUAGE, LANGUAGES } from './constants';
import { onChangeLanguage } from './utils';

const { i18nDebug } = config;

const InternationalizationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [userLanguage, setUserLanguage] = useState('');

  const token = useAppSelector(userToken);

  const { data } = useQuery(USER_PREFERENCES, { skip: !token, fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data) {
      const newUserLanguage = pathOr(Language.english, ['myPreferences', 'language'], data);
      const formattedUserLanguage = newUserLanguage.toLowerCase();

      setUserLanguage(formattedUserLanguage);
    }
  }, [data]);

  useEffect(() => {
    if ((userLanguage || !token) && !initialized) {
      const forceLanguage = localStorage.getItem(FORCE_LANGUAGE);
      const pathLanguage = getCurrentLanguageFromPath(LANGUAGES).replace('/', '');
      const selectedLanguage = pathLanguage || Language.english;

      const language = forceLanguage || userLanguage || selectedLanguage;

      if (language !== selectedLanguage) {
        onChangeLanguage(language, window.location.pathname);
      }

      initTranslation(selectedLanguage);

      if (forceLanguage) {
        localStorage.removeItem(FORCE_LANGUAGE);
      }
    }
  }, [userLanguage, token]);

  async function initTranslation(lng: string) {
    i18n
      .use(initReactI18next)
      .use(backend)
      .init({
        lng,
        debug: i18nDebug,
        partialBundledLanguages: true,
        resources: {},
        fallbackLng: Language.english,
        supportedLngs: LANGUAGES,
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      })
      .then(() => setInitialized(true))
      .catch(() => setInitialized(false));
  }

  return initialized ? <I18nextProvider i18n={i18n}>{children}</I18nextProvider> : <Loader pages />;
};

export const useLocalization = () => {
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();

  const [language, setLanguage] = useState(Language.english);

  const token = useAppSelector(userToken);

  const [updateLanguage] = useMutation(SET_PREFERENCE);

  useEffect(() => {
    if (i18n.language !== language) {
      setLanguage(i18n.language as Language);
    }
  }, [i18n.language]);

  const handleTranlsate = (key: string): string => {
    try {
      return t(key);
    } catch {
      return key;
    }
  };

  const handleTranslateCustomNamespace = (key: string, ns: string): Promise<string> | string => {
    try {
      const loaded = i18n.hasLoadedNamespace(ns);

      if (!loaded) {
        return i18n.loadNamespaces(ns).then(() => {
          return t(key, { ns });
        });
      }

      return t(key, { ns });
    } catch {
      return key;
    }
  };

  const handleChangeLanguage = async (newLanguage: string) => {
    if (language !== newLanguage && token) {
      localStorage.setItem(FORCE_LANGUAGE, newLanguage);

      await updateLanguage({ variables: { preference: { language: newLanguage.toUpperCase() } } });
    }

    return onChangeLanguage(newLanguage, pathname, i18n);
  };

  return {
    language,
    translate: handleTranlsate,
    translateCustomNamespace: handleTranslateCustomNamespace,
    onChangeLanguage: handleChangeLanguage,
  };
};

export default InternationalizationProvider;
