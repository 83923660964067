import clsx from 'clsx';

import chartIcon from 'assets/img/HomePage/NvigationImages/dividends1.webp';
import percentIcon from 'assets/img/HomePage/NvigationImages/dividends2.webp';

import { Links } from 'components/constants';
import { useLocalization } from 'components/Internationalization';

import PromoBlock from '../PromoBlock';

import styles from './styles.module.scss';

const DividendsNavigationItem: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <PromoBlock
      className={styles.wrapper}
      bgClassName={clsx(styles.bg, styles.dividendsBg)}
      title={translate('homepage.welcome.dividends.title')}
      subtitle={translate('homepage.welcome.dividends.description')}
      link={Links.dividends}
    >
      <img src={percentIcon} alt="icon" className={styles.dividendPercent1} />
      <img src={percentIcon} alt="icon" className={styles.dividendPercent2} />
      <img src={percentIcon} alt="icon" className={styles.dividendPercent3} />
      <img src={chartIcon} alt="icon" className={styles.dividendChart} />
    </PromoBlock>
  );
};

export default DividendsNavigationItem;
