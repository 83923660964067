import { UrlType } from 'types';

export const getFormattedWager = (wager: string | number): string => {
  const parsedWager = Number(wager);

  if (Number.isNaN(parsedWager)) {
    return String(wager);
  }

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parsedWager);
};

export const prepareDataForCollect = () => {
  console.log('test');
};

export const getUrlType = (url: string): UrlType => {
  if (url.includes('http')) return 'external';
  if (url.includes('/')) return 'internal';

  return 'popup';
};
