import giftIcon from 'assets/img/Marketing/gift.webp';
import giftHandIcon from 'assets/img/common/giftHand.webp';
import confetiIcon from 'assets/img/Marketing/confeti.webp';

import { useLocalization } from 'components/Internationalization';
import { ButtonType } from 'components/Base/Button/types';

import Button from 'components/Base/Button';

import styles from './styles.module.scss';
import { IBonusMarketing } from './types';

const BonusMarketing: React.FC<IBonusMarketing> = ({ token, onDeposit }) => {
  const { translate } = useLocalization();

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapperTitleImage}>
          <img src={giftHandIcon} alt="icon" className={styles.giftHandImage} />
          <div className={styles.wrapperTitle}>
            <p className={styles.title}>{translate('marketing.bonus.banner.title')}</p>
            <p className={styles.descriptionDesc}>{translate('marketing.bonus.banner.description.desk')}</p>
            <p className={styles.descriptionMob}>{translate('marketing.bonus.banner.description.mob')}</p>
            <img src={giftIcon} alt="icon" className={styles.giftImage} />
          </div>
        </div>
        <div className={styles.wrapperButton}>
          <Button
            title={token ? translate('popups.wallet.nav.deposit') : translate('header.signup')}
            className={styles.button}
            variant={ButtonType.secondary}
            onClick={onDeposit}
          />
          <img src={confetiIcon} alt="icon" className={styles.confetiImage} />
        </div>
      </div>
    </>
  );
};

export default BonusMarketing;
