import clsx from 'clsx';

import walletIcon from 'assets/img/HomePage/NvigationImages/cashback1.webp';
import chartIcon from 'assets/img/HomePage/NvigationImages/cashback2.webp';

import { Links } from 'components/constants';
import { getCoinSrc } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import PromoBlock from '../PromoBlock';

import styles from './styles.module.scss';

const CashbackNavigationItem: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <PromoBlock
      className={styles.wrapper}
      bgClassName={clsx(styles.bg, styles.cashbackBg)}
      title={translate('homepage.welcome.cashback.title')}
      subtitle={translate('homepage.welcome.cashback.description')}
      link={Links.cashback}
    >
      <img src={chartIcon} alt="icon" className={styles.cashbackChart} />
      <img src={getCoinSrc('STAR')} alt="icon" className={styles.cashbackStar} />
      <img src={walletIcon} alt="icon" className={styles.cashbackWallet} />
    </PromoBlock>
  );
};

export default CashbackNavigationItem;
