/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDataState } from './data.state';
import {
  ISetFreeSpinsSettings,
  ISetGames,
  ISetFavoriteGamesList,
  ISetIsActiveUserBonus,
  ISetIsDisplayBonus,
  ISetUserBonuses,
  ISetVipStatus,
  ISetActiveBonusTokens,
  ISetBonusMarketing,
} from './data.types';

const dataReducers = {
  setFavoriteGamesList: (state: IDataState, action: ISetFavoriteGamesList): void => {
    state.favoriteGamesList = action.payload;
  },
  setUserBonuses: (state: IDataState, action: ISetUserBonuses): void => {
    state.userBonuses = action.payload;
  },
  setIsDisplayBonus: (state: IDataState, action: ISetIsDisplayBonus): void => {
    state.isDisplayBonus = action.payload;
  },
  setIsActiveUserBonus: (state: IDataState, action: ISetIsActiveUserBonus): void => {
    state.isActiveUserBonus = action.payload;
  },
  setFreeSpinsSettings: (state: IDataState, action: ISetFreeSpinsSettings): void => {
    state.freeSpinsSettings = action.payload;
  },
  setGames: (state: IDataState, action: ISetGames): void => {
    state.games = action.payload;
  },
  setVipStatus: (state: IDataState, action: ISetVipStatus): void => {
    state.vipStatus = action.payload;
  },
  setActiveBonusTokens: (state: IDataState, action: ISetActiveBonusTokens): void => {
    state.activeBonusTokens = action.payload;
  },
  setBonusMarketing: (state: IDataState, action: ISetBonusMarketing): void => {
    state.bonusMarketing = action.payload;
  },
};

export default dataReducers;
