import { gql } from '@apollo/client';

export const MONTHLY_WAGER = gql`
  query userCurrentMonthlyWager {
    userCurrentMonthlyWager
  }
`;

export const USER_PREFERENCES = gql`
  query myPreferences {
    myPreferences {
      language
    }
  }
`;
