import clsx from 'clsx';
import { useState } from 'react';

import inactiveStars from 'assets/img/HomePage/NvigationImages/inactive-stars.webp';
import activeStars from 'assets/img/HomePage/NvigationImages/active-stars.webp';

import Link from 'components/Base/Links/Link';

import styles from './styles.module.scss';
import { IPromoBlock } from './types';

const PromoBlock: React.FC<IPromoBlock> = ({
  title,
  subtitle,
  link,
  className,
  bgClassName,
  externalLink = false,
  label = '',
  children,
}) => {
  const [active, setActive] = useState(false);

  const handleMouseEnter = () => {
    setActive(true);
  };

  const hanldeMouseLeave = () => {
    setActive(false);
  };

  const content = (
    <>
      <img className={styles.img} src={active ? activeStars : inactiveStars} alt="stars" />
      <div className={bgClassName} />
      <h3 className={styles.title}>{title}</h3>
      <h4 className={styles.subtitle}>{subtitle}</h4>
      {label ? <h6 className={styles.label}>{label}</h6> : null}
      {children}
    </>
  );

  return (
    <div className={clsx(styles.wrapper, className)} onMouseEnter={handleMouseEnter} onMouseLeave={hanldeMouseLeave}>
      {externalLink ? (
        <a className={styles.content} href={link} target="_blank" rel="noreferrer">
          {content}
        </a>
      ) : (
        <Link className={styles.content} to={link}>
          {content}
        </Link>
      )}
    </div>
  );
};

export default PromoBlock;
