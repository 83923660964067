import { media } from 'config';

import styles from './styles.module.scss';
import { IWelcomeSlide } from './types';

const WelcomeSlide: React.FC<IWelcomeSlide> = ({ slide, onClick }) => {
  return (
    <button className={styles.wrapper} onClick={() => onClick(slide.id, slide.deskImage)}>
      <picture>
        <source srcSet={`${media.slider}${slide.deskImage}`} media="(min-width: 1024px)" />
        <source srcSet={`${media.slider}${slide.tabImage}`} media="(min-width: 700px)" />
        <img src={`${media.slider}${slide.mobImage}`} alt={`Slider image ${slide.id}`} />
      </picture>
    </button>
  );
};

export default WelcomeSlide;
