import { i18n } from 'i18next';

import { getCurrentLanguageFromPath } from 'func/localization';

import { LANGUAGES } from './constants';
import { Language } from './types';

const changeLanguage = (newLanguage: string, newUrl: string, client?: i18n) => {
  if (client) {
    client.changeLanguage(newLanguage);
  }

  window.location.replace(newUrl || '/');
};

export const onChangeLanguage = async (newLanguage: string, pathname: string, client?: i18n) => {
  const currentLanguage = getCurrentLanguageFromPath(LANGUAGES);

  if (newLanguage === Language.english) {
    const newUrl = currentLanguage ? pathname.replace(currentLanguage, '') : pathname;

    console.log(currentLanguage, newUrl);

    changeLanguage(newLanguage, newUrl, client);
    return;
  }

  const urlLanguage = `/${newLanguage}`;
  const newUrl = currentLanguage ? pathname.replace(currentLanguage, urlLanguage) : urlLanguage + pathname;

  changeLanguage(newLanguage, newUrl, client);
};
