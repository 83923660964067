import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { useQuery } from '@apollo/client';
import 'react-circular-progressbar/dist/styles.css';

import config from 'config';
import SocketProvider from 'context/contextSocket';
import NotificationProvider from 'context/contextNotifications';
import IntercomProvider from 'context/contextIntercom';
import { PopUpsProvider, TokenCodeProvider } from 'context';
import { WalletProvider } from 'context/contextWallet';
import { GET_MAINTENANCE_MODE } from 'graphQl/query/security/maintenanceMode';

import AppRoute from 'components/AppRoute';
import MaintenanceMode from 'components/Pages/MaintenanceMode';
import Loader from 'components/Base/Loader';
import Password from 'components/Pages/Password';
import LoginLayer from 'components/LoginLayer';
import ReferralLayer from 'components/Referral';
import MarketingLayer from 'components/MarketingLayer';

const { devPassword } = config;

const App: React.FC = () => {
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState(false);

  const { data, loading } = useQuery(GET_MAINTENANCE_MODE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      setMode(data.getMaintenanceMode);
    }
  }, [data]);

  useEffect(() => {
    const trigger = sessionStorage.getItem('password') || password;
    const userPassword = trigger || password;

    if (devPassword && userPassword === devPassword) {
      setPassword(devPassword);
    }
  }, [password]);

  if (loading || mode === null) return <Loader />;
  return (
    <LoginLayer>
      {mode ? (
        <MaintenanceMode />
      ) : !devPassword || password ? (
        <BrowserRouter>
          <ReferralLayer>
            <PopUpsProvider>
              <TokenCodeProvider>
                <IntercomProvider>
                  <SocketProvider>
                    <WalletProvider>
                      <NotificationProvider>
                        <CookiesProvider>
                          <MarketingLayer>
                            <AppRoute />
                          </MarketingLayer>
                        </CookiesProvider>
                      </NotificationProvider>
                    </WalletProvider>
                  </SocketProvider>
                </IntercomProvider>
              </TokenCodeProvider>
            </PopUpsProvider>
          </ReferralLayer>
        </BrowserRouter>
      ) : (
        <Password setPassword={setPassword} />
      )}
    </LoginLayer>
  );
};

export default App;
