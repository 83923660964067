import { gql } from '@apollo/client';

export const SET_PREFERENCE = gql`
  mutation setPreference($preference: PreferenceDto!) {
    setPreference(preferenceDto: $preference) {
      accountActivityAlert
      newsEmail
      promoEmail
    }
  }
`;
