import clsx from 'clsx';

import peopleIcon from 'assets/img/HomePage/NvigationImages/referral1.webp';
import usdIcon from 'assets/img/HomePage/NvigationImages/referral2.webp';

import { Links } from 'components/constants';
import { getCoinSrc } from 'func/common';
import { useLocalization } from 'components/Internationalization';

import PromoBlock from '../PromoBlock';

import styles from './styles.module.scss';

const ReferralNavigationItem: React.FC = () => {
  const { translate } = useLocalization();

  return (
    <PromoBlock
      className={styles.wrapper}
      bgClassName={clsx(styles.bg, styles.referralBg)}
      title={translate('homepage.welcome.referral.title')}
      subtitle={translate('homepage.welcome.referral.description')}
      label={translate('homepage.welcome.referral.label')}
      link={Links.referral}
    >
      <img src={usdIcon} alt="icon" className={styles.referralUsd} />
      <img src={getCoinSrc('STAR')} alt="icon" className={styles.referralStar} />
      <img src={peopleIcon} alt="icon" className={styles.referralPeople} />
    </PromoBlock>
  );
};

export default ReferralNavigationItem;
