export const DEPOSIT_TRANSACTION = {
  input: {
    dateFrom: null,
    dateTo: null,
    limit: 1,
    offset: 0,
    order: {
      createdAt: 'DESC',
    },
    type: 'Deposit',
  },
};

export const NEW_USER_BONUS = 'new-user-bonus';
